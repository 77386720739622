import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import ScrollToTop from "./ScrollToTop";
import "../blocks/App.css";

import Header from "./Header";
import Footer from "./Footer";
import PageContent from "./PageContent";
import CardModule from "./CardModule";
import CardModuleBook from "./CardModuleBook";
import AboutUs from "./AboutUs";
import JuanCarlos from "./Packages/JuanCarlos";
import XaviLift from "./Packages/XaviLift";
import HeaderBurger from "./HeaderBurger";
import ImageGrid from "./ImageGrid";
import PrivacyPolicy from "./PrivacyPolicy";
import BlogPost from "./BlogPost";
import FetchPosts from "./FetchPosts";

TagManager.initialize({ gtmId: "GTM-PQBZQLL6" });

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="page">
        <div className="page__content">
          <Header />
          <HeaderBurger />
          <Routes>
            <Route path="/" element={<PageContent />} />
            <Route path="/sobre-nosotros" element={<AboutUs />} />
            <Route path="/entrenadores-personales" element={<CardModule />} />
            <Route
              path="/reserva-una-sesion-de-prueba"
              element={<CardModuleBook />}
            />
            <Route path="/juancarlos" element={<JuanCarlos />} />
            <Route path="/xavilift" element={<XaviLift />} />
            <Route path="/instalaciones" element={<ImageGrid />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/blog" element={<FetchPosts />} />
            <Route exact path="/blog/:slug" element={<BlogPost />} />
            {/* Add more routes for each package here */}
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
